/* responsive settings */
$responsiveActive: true;
$breakpoints: (
  xs: 600px,
  sm: 960px,
  md: 1280px,
  xl: 1920px
);

/* RESPONSIVE */
@mixin breakpoint-min($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @if $responsiveActive == true {
      @media (min-width: $breakpoint-value) {
        @content;
      }
    } @else {
      @content;
    }
  } @else {
    @warn 'Breakpoint not found: #{$breakpoint}.';
  }
}

@mixin breakpoint-max($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @if $responsiveActive == true {
      @media (max-width: ($breakpoint-value - 0.01)) {
        @content;
      }
    } @else {
      @content;
    }
  } @else {
    @warn 'Breakpoint not found: #{$breakpoint}';
  }
}

@mixin breakpoint-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    @if $responsiveActive == true {
      @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 0.01)) {
        @content;
      }
    } @else {
      @content;
    }
  } @else {
    @if (map-has-key($breakpoints, $lower) == false) {
      @warn 'Lower breakpoint not found: #{$lower}.';
    }
    @if (map-has-key($breakpoints, $upper) == false) {
      @warn 'Upper breakpoint not found: #{$upper}.';
    }
  }
}
