@use '@angular/material' as mat;

$header-height: 56px;
$sidebar-width: 15rem;
$chat-width: 20rem;

$app-primary:  mat.define-palette(mat.$light-blue-palette, 800);
$app-primary-light:  mat.define-palette(mat.$light-blue-palette, 100);
$app-primary-dark:  mat.define-palette(mat.$light-blue-palette, 900);
$app-accent: mat.define-palette(mat.$green-palette, 600);
$app-warn:    mat.define-palette(mat.$deep-orange-palette, A700);
$font-stack: 'Roboto', 'sans-serif';


$grey-6: #F0F0F0;
$grey-12: #E2E2E2;
$grey-24: #D2D2D2;
$grey-70: #4D4D4D;
$black-3-opacity: rgba(black, 0.03);
$black-6-opacity: rgba(black, 0.06);
$black-9-opacity: rgba(black, 0.09);
$black-12-opacity: rgba(black, 0.12);
$black-54-opacity: rgba(black, 0.54);
$black-87-opacity: rgba(black, 0.87);
$black-30-opacity: rgba(black, 0.30);
$white-12-opacity: rgba(white, 0.12);
$white-30-opacity: rgba(white, 0.30);
$white-54-opacity: rgba(white, 0.54);

$config: mat.define-typography-config();
$custom-typography: mat.define-typography-config(
  $font-family: $font-stack,
  $input: mat.define-typography-level(mat.font-size($config, input), 1.4, mat.font-weight($config, input))
);
