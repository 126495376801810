@use '@angular/material' as mat;
@import 'src/styles/theme';

body {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #fff;
    -webkit-text-fill-color: $black-87-opacity;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .mat-input-element {
    font-size: 16px;
  }

  textarea {
    &.mat-input-element {
      overflow: hidden;
    }
  }

  .mat-form-field-infix {
    width: auto;
  }

  .mat-form-field {
    &.readonly {
      .mat-form-field-underline {
        background: none !important;
        border-bottom: 1px dashed $black-54-opacity;
        .mat-form-field-ripple {
          display: none ! important;
        }
      }

      &.ng-invalid {
        .mat-form-field-underline {
          border-color: mat.get-color-from-palette($app-warn);
        }
      }
    }
  }
  .auto-width {
    .mat-form-field {
      width: auto !important;
    }
    .mat-select-value {
      max-width: 100%;
      width: 100%;
    }
    .mat-select-trigger {
      min-width: 100%;
    }
    .mat-select-arrow-wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }

  .mat-chip-list-wrapper input.mat-input-element {
    margin: 4px !important;
  }

  .mat-chip.mat-standard-chip {
    background-color: mat.get-color-from-palette($app-primary-light) !important;
    border-radius: 5px;
  }
  .mat-chip-list-wrapper .mat-standard-chip {
    margin: 2px 4px !important;
  }
  .mat-standard-chip {
    min-height: 22px;
  }
  .mat-chip.mat-standard-chip .mat-chip-remove {
    color: mat.get-color-from-palette($app-warn) !important;
  }
}
