body {
  .mat-button-base {
    text-transform: uppercase;

    &.mat-button,
    &.mat-flat-button {
      border-radius: 5px;
      min-width: 0;
    }

    .mat-button-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      > * {
        &:last-child:not(:first-child) {
          margin-left: 1rem;
        }
      }
    }
    &.mobile-hide-text {
      @include breakpoint-max(xs) {
        padding: 0;
        .mat-button-wrapper {
          width: 36px;
          height: 36px;
          > span {
            display: none;
          }

          > * {
            &:last-child:not(:first-child) {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

