@use '@angular/material' as mat;
body {
  .mat-snack-bar-container {
    .mat-simple-snackbar {
      display: flex;
      justify-content: center;
    }
    &.error {
      background: mat.get-color-from-palette($app-warn);
    }
    &.success {
      background: mat.get-color-from-palette($app-accent);
    }
  }
}

