body {
  .mat-radio-group {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .mat-radio-button {
    display: flex;
    &:not(:first-of-type) {
      padding-top: 1rem;
    }
  }

  .mat-radio-label-content {
    white-space: initial;
  }
}

